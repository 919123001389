<template>
  <div class="short-news">
    <div class="content">
      <div class="left">
        <div class="short-box" v-for="i in 8" :key="i">
          <div class="date-box">
            <div>9月</div>
            <div>15</div>
          </div>
          <div class="detail">
            <div class="title">
              央行：境内投资者可使用人民币或外汇参与“南向通”
            </div>
            <div class="tag-btn">
              <span>1小时前</span>
              <span>分享至</span>
              <span>
                <i class="el-icon-platform-eleme"></i>
              </span>
              <span>
                <i class="el-icon-platform-eleme"></i>
              </span>
              <span>
                <i class="el-icon-platform-eleme"></i>
              </span>
            </div>
            <div class="desc">
              36氪获悉，嘉楠科技公布2021年第二季度财报。
              财报显示，二季度，公司营收10.82亿元，同比增长507.3%，
              为历史最佳单季收入；净利润为2.45亿元，上年同期亏损1680万元，
              净利润亦创下公司上市后单季新高。据嘉楠科技介绍，
              二季度营收大幅增长主要得益于销售总算力增长，
              二季度公司销售总算力为594万TH/s，同比增长127%，
              环比增长200%，创单季历史新高。
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="top-list">
          <div class="title">
            <span>24小时热榜</span>
            <span>查看更多榜单</span>
          </div>
          <div class="big-box" v-for="i in 2" :key="i">
            <div class="content">
              <div class="desc">
                焦点分析 | “买不买？”，苹果用加量不加价的iPhone 13回答消费者
              </div>
            </div>
          </div>

          <div class="small-box" v-for="i in 8" :key="i">
            <div class="poster">
              <img
                src="https://dummyimage.com/105x70/4b5cc4"
                alt=""
                srcset=""
              />
            </div>
            <div class="desc">
              <div class="name">
                8点1氪 | iPhone
                13国行版5999元起售；飞猪为“环球优速通出票失败”加赔30%；人民日报时评：规范医美广告
              </div>
              <div class="time">9小时前</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.short-news {
  padding-top: 30px;
  .content {
    width: 1200px;
    margin: 0 auto;

    display: flex;

    .left {
      flex: 1 1 auto;
      margin-right: 15px;
    }

    .right {
      flex: 0 0 auto;
      width: 300px;
    }
  }

  .short-box {
    display: flex;

    & + .short-box {
      margin-top: 20px;

      .date-box {
        visibility: hidden;
      }
    }

    .date-box {
      flex: 0 0 auto;
      width: 40px;
      margin-right: 20px;
      div:nth-child(1) {
        font-size: 14px;
        color: #4285f4;
        letter-spacing: 1.75px;
        text-align: center;
        height: 21px;
        line-height: 20px;
        border: 1px solid #4285f4;
        border-bottom: none;
      }

      div:nth-child(2) {
        font-size: 26px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        height: 37px;
        line-height: 37px;
        background: #4285f4;
      }
    }

    .detail {
      flex: 1 1 auto;
      .title {
        display: block;
        line-height: 25px;
        font-size: 18px;
        font-weight: 700;
        color: #262626;
        transition: 0.5s;

        max-width: 700px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .tag-btn {
        height: 20px;
        line-height: 20px;
        margin: 6px 0 10px;
        font-size: 12px;
        color: #a7a7a7;
        span:nth-child(1) {
          margin-right: 20px;
        }
        span {
          margin-right: 8px;
        }
      }

      .desc {
        padding-right: 20px;
        line-height: 24px;
        font-size: 14px;
        color: #787878;
      }
    }
  }

  .top-list {
    .title {
      margin-bottom: 15px;
      height: 28px;
      line-height: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span:nth-child(1) {
        font-size: 20px;
        color: #262626;
        padding-left: 4px;
      }

      span:nth-child(2) {
        cursor: pointer;
        font-size: 13px;
        color: #787878;
        padding-right: 10px;
      }
    }

    .big-box {
      cursor: pointer;
      width: 300px;
      height: 200px;
      overflow: hidden;

      &:active,
      &:hover {
        .content {
          background-size: 110% 110%;
        }
      }

      & + .big-box {
        margin-top: 15px;
      }

      .content {
        position: relative;
        width: 300px;
        height: 200px;
        background-image: url("https://dummyimage.com/300x200/4b5cc4");
        background-position: 50% 50%;
        background-size: 100% 100%;
        background-repeat: no-repeat;

        transition: all 0.5s;

        .desc {
          font-size: 14px;
          line-height: 20px;
          color: #fff;
          text-align: justify;

          padding: 0 10px;
          bottom: 0;
          position: absolute;
          height: 30%;

          background-image: linear-gradient(
            -180deg,
            transparent 13%,
            rgba(0, 0, 0, 0.65) 97%
          );

          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;

          word-break: break-all;
          text-overflow: ellipsis;
        }
      }
    }

    .small-box {
      margin: 15px 0;
      display: flex;

      &:hover,
      &:active {
        .poster {
          img {
            transform: scale(1.2);
          }
        }
      }

      .poster {
        flex: 0 0 auto;
        width: 105px;
        height: 70px;
        overflow: hidden;
        img {
          transition: all 0.2s;
        }
      }

      .desc {
        margin-left: 10px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .name {
          width: 100%;

          font-size: 13px;
          color: #262626;
          letter-spacing: 0;
          text-align: justify;
          line-height: 20px;
          max-height: 40px;

          overflow: hidden;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
        }

        .time {
          font-size: 12px;
          color: #a7a7a7;
        }
      }
    }
  }
}
</style>